































































































































































import Base from '@/mixins/Base.vue';
import { ITip, ITipResponse } from '@/interfaces/tip';

const component = Base.extend({
    components: {
    },

    data() {
        return {
            currentLanguage: 'en',
            apiKey: process.env.VUE_APP_TINY_MCE_API_KEY,
            tip: undefined as any | ITip,
        };
    },

    mounted() {
        this.gettip();
    },
    methods: {
        gettip() : void{
            this.get<ITipResponse>(`tips/${this.$route.params.id}`).then(({ data }) => {
                this.tip = data.data;
            });
        },
        saveTip() : void{
            this.put<ITipResponse>(`tips/${this.$route.params.id}`, {
                ...this.tip,
            }).then(({ data }) => {
                this.tip = data.data;
                this.$emit('success', { message: `post ${data.data.id} updated` });
            });
        },

        uploadTipPicture(): void {
            const formData = new FormData();

            if (this.tip.picture) {
                formData.append('picture', this.tip.picture as any);
            }

            this.post<ITipResponse>(`tips/${this.$route.params.id}/picture`, formData)
                .then(({ data }) => {
                    this.tip.picture_url = data.data.picture_url;
                    this.$emit('success', { message: 'picture updated' });
                })
                .finally(() => {
                    this.tip.picture = null;
                });
        },
    },
});

export default component;
