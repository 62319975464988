var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"level"},[_c('div',{staticClass:"level-left"},[_c('h1',{staticClass:"level-item"},[_c('sp-link',{staticClass:"mr-3",attrs:{"tag":"b-button","to":{name:'tips.list'},"icon-left":"arrow-left"}}),( ! _vm.tip)?_c('b-skeleton',{attrs:{"size":"is-medium","animated":"","width":"300"}}):[_c('h1',{staticClass:"title"},[_vm._v(" Edit "+_vm._s(_vm._f("truncate")(_vm.tip.title,25))+" ")])]],2)])]),(_vm.tip)?_c('div',[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
var valid = ref.valid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return passes(_vm.saveTip)}}},[_c('div',{staticClass:"block"},[_c('div',{staticClass:"subtitle"},[_vm._v(" General ")]),_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":'translations.text.'+_vm.currentLanguage,"tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Text","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-field',[_c('editor',{attrs:{"api-key":_vm.apiKey,"disabled":_vm.isLoading},model:{value:(_vm.tip.translations.text[_vm.currentLanguage]),callback:function ($$v) {_vm.$set(_vm.tip.translations.text, _vm.currentLanguage, $$v)},expression:"tip.translations.text[currentLanguage]"}})],1)],1)]}}],null,true)}),_c('b-field',{attrs:{"horizontal":"","label":"Picture"}},[_c('div',{staticClass:"media"},[(_vm.tip.picture_url)?_c('div',{staticClass:"media-left"},[_c('figure',{staticClass:"image is-128x128",style:({height: 'auto'})},[_c('img',{attrs:{"src":_vm.tip.picture_url,"alt":""}})])]):_vm._e(),_c('div',{staticClass:"media-content"},[_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"picture","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-upload',{staticClass:"file-label",attrs:{"disabled":_vm.isLoading},on:{"input":function($event){return _vm.uploadTipPicture()}},model:{value:(_vm.tip.picture),callback:function ($$v) {_vm.$set(_vm.tip, "picture", $$v)},expression:"tip.picture"}},[_c('div',{staticClass:"button",attrs:{"disabled":_vm.isLoading}},[_c('b-icon',{attrs:{"icon":"upload"}}),_c('span',[_vm._v("Upload file")]),(_vm.tip.picture)?_c('span',{staticClass:"tag ml-2"},[_vm._v(" "+_vm._s(_vm.tip.picture.name)+" ")]):_vm._e()],1)]),(_vm.tip.picture_url)?_c('b-button',{attrs:{"disabled":_vm.isLoading},on:{"click":function($event){_vm.tip.picture_url = null}}},[_vm._v(" Remove ")]):_vm._e()],1)]}}],null,true)})],1)])]),_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"is_published","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Published","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-switch',{attrs:{"disabled":_vm.isLoading},model:{value:(_vm.tip.is_published),callback:function ($$v) {_vm.$set(_vm.tip, "is_published", $$v)},expression:"tip.is_published"}})],1)]}}],null,true)})],1),_c('b-field',{attrs:{"horizontal":""}},[_c('b-button',{attrs:{"type":"is-primary","icon-left":"save","disabled":! valid || _vm.isLoading},on:{"click":function($event){return _vm.saveTip()}}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1)],1)]}}],null,false,3601704382)})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }